document.addEventListener('DOMContentLoaded', () => {
  setupIntersection({
    className: 'header__dishes',
    callback: (elements) =>
      elements.forEach((el) => el.classList.add('header__dishes--animated')),
  });
  setupIntersection({
    className: 'principles__item',
    threshold: window.innerWidth < 1024 ? 0.1 : 0.8,
    callback: (elements) =>
      elements.forEach((el) => el.classList.add('principles__item--animated')),
  });
  setupIntersection({
    className: 'visual__mobile-content',
    callback: (elements) =>
      elements.forEach((el) =>
        el.classList.add('visual__mobile-content--animated')
      ),
  });
  setTimeout(setupVisual, 1500);
  updateLinks();

  const year = new Date().getFullYear();
  document.getElementById('legal-year').innerText = year;

  reportMetrics();
});

const canIntersect = 'IntersectionObserver' in window;

const setupIntersection = ({ className, threshold = 0.3, callback }) => {
  const elements = document.querySelectorAll(`.${className}`);
  if (!canIntersect) {
    callback(elements);
    return;
  }

  const observer = new IntersectionObserver(
    (changes) => {
      changes.forEach((change) => {
        if (change.isIntersecting) {
          callback([change.target]);
          observer.unobserve(change.target);
        }
      });
    },
    {
      threshold,
    }
  );

  elements.forEach((el) => observer.observe(el));
};

const setupVisual = () => {
  const visual = document.querySelector('.visual');
  const linesContainer = visual.querySelector('.visual__lines');
  const lines = linesContainer.querySelectorAll('svg rect');
  const dish = visual.querySelector('.visual__dish');
  const statuses = visual.querySelectorAll('.visual__status');

  const ACTIVATION_SHIFT_IN_PIXELS = 32;
  const LINE_WITH_DELAY_SPEED_IN_PIXELS =
    (visual.clientHeight - window.innerHeight) / lines.length;
  const LINE_SPEED_IN_PIXELS = LINE_WITH_DELAY_SPEED_IN_PIXELS - 200;
  const LINE_WIDTH = linesContainer.clientWidth;
  const LINE_MIN_WIDTH = 60;
  const LINE_HEIGHT = lines[0].parentElement.clientHeight;
  const SCREW_LINE_WIDTH = lines[1].parentElement.clientWidth;
  const SECOND_ROW_MARGIN = lines[2].parentElement.parentElement.offsetTop;
  const DISH_SHIFT = (dish.clientWidth - LINE_HEIGHT) / 2;

  addAnimationListener(document, 'scroll', () => {
    const { scrollTop } = document.documentElement;
    if (window.innerWidth < 920) return;

    const SCROLL_ACTIVE_FROM_IN_PIXELS =
      visual.offsetTop - ACTIVATION_SHIFT_IN_PIXELS;
    const SCROLL_ACTIVE_TILL_IN_PIXELS =
      visual.offsetTop + visual.clientHeight - window.innerHeight;

    const active = scrollTop >= SCROLL_ACTIVE_FROM_IN_PIXELS;
    const end = scrollTop >= SCROLL_ACTIVE_TILL_IN_PIXELS;
    visual.classList.toggle('visual--active', active);
    visual.classList.toggle('visual--end', end);
    if (!active) return;

    const currentIndex = Math.floor(
      (scrollTop - SCROLL_ACTIVE_FROM_IN_PIXELS) /
        LINE_WITH_DELAY_SPEED_IN_PIXELS
    );
    const shift = Math.min(
      ((scrollTop - SCROLL_ACTIVE_FROM_IN_PIXELS) %
        LINE_WITH_DELAY_SPEED_IN_PIXELS) /
        LINE_SPEED_IN_PIXELS,
      1
    );

    statuses.forEach((status) => {
      status.classList.toggle(
        'active',
        status.dataset.number <= currentIndex + 1 + (shift === 1 ? 1 : 0)
      );
    });

    let index = -1;
    lines.forEach((line) => {
      index += 1;

      line.parentNode.parentNode.classList.toggle(
        'visual__line--active',
        index <= currentIndex
      );
      if (index < currentIndex) {
        line.style.transition = 'width 0.05s';
        line.style.width = '100%';
        return;
      }

      if (index > currentIndex) {
        line.style.transition = 'width 0.05s';
        line.style.width = `${LINE_MIN_WIDTH}px`;
        return;
      }

      let width = shift * (index % 2 === 0 ? LINE_WIDTH : SCREW_LINE_WIDTH);
      width = Math.max(width, LINE_MIN_WIDTH);

      line.style.transition = 'width .1s';
      line.style.width = `${width}px`;

      let x = -(width - DISH_SHIFT);
      let y = index > 1 ? SECOND_ROW_MARGIN : 0;
      if (index % 2 !== 0) {
        x = -(LINE_WIDTH - width * 0.9025) - 7;
        y += width * Math.sin((Math.PI / 180) * 26) - 26;
      }
      dish.style.transform = `translate(${x}px, ${y}px)`;
    });
  });
};

const addAnimationListener = (element, event, callback) => {
  let timeout;
  const handler = () => {
    if (timeout) return;
    timeout = requestAnimationFrame(() => {
      callback();
      timeout = undefined;
    });
  };
  element.addEventListener(event, handler, { passive: true });
};

const updateLinks = () => {
  const links = [...document.querySelectorAll('a[data-kind="service"]')];
  links.forEach((link) => {
    link.href = `${link.href}${location.search}`;
  });
};

const reachMetricGoal = (goal) => {
  const key = process.env.REACT_APP_YANDEX_METRIKA_KEY;
  if (key) {
    window.ym(key, 'reachGoal', goal);
  }
};

const reportMetrics = () => {
  let threeSecondsIntervalTimes = 5;
  const threeSecondsInterval = setInterval(() => {
    if (threeSecondsIntervalTimes <= 0) {
      clearInterval(threeSecondsInterval);
      return;
    }

    threeSecondsIntervalTimes--;

    reachMetricGoal('DURATION_EVERY_3_SEC');
  }, 3000);

  const times = [16, 30, 45, 60, 75, 90, 120, 150, 180, 210, 240];
  times.forEach((seconds) => {
    setTimeout(
      () => reachMetricGoal(`DURATION_${seconds}_SEC`),
      seconds * 1000
    );
  });
};
